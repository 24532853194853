import {
  Box,
  Fade,
  Typography,
  Container,
  styled,
  Card,
  Grid,
  Chip,
  useTheme,
  Button,
  Popover
} from '@mui/material';

import React, { useState, useEffect, useRef } from 'react';
import background from '../../assets/images/backgrounds/applicationbg.svg';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import PositionCard from '../../components/PositionCard/PositionCard';
import PositionPopup from '../../components/PositionPopup/PositionPopup';
import PropulseButton from '../../components/core/Universal/PropulseButton';
import getTimeRemaining from '../../components/misc/Countdown/getTimeRemaining';
import * as Positions from './Application.json';
import ApplicationBackground from './ApplicationBackground';

const PropulseCard = styled(Card)`
  border-radius: 20px;
  box-shadow: none;
  overflow: hidden;
`;

export default function Application() {
  const theme = useTheme();
  const [chosenDivision, setChosenDivision] = useState('All Positions');
  const [chosenPosition, setChosenPosition] = useState('');
  const [popupContent, setPopupContent] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const componentRef = useRef(null);

  const PositionString = JSON.stringify(Positions);
  const PositionObject = JSON.parse(PositionString).application;

  const applicationType = 'Ants';
  const fetchApplicationType = PositionObject.find(
    (app) => app.type === applicationType
  );
  const currentDivisions = fetchApplicationType.positions.map(
    (position) => position
  );

  const timeRemaining = getTimeRemaining('2025-03-23T23:59:59');

  const getInformationPosition = (
    fetchApplicationType,
    divisionName,
    position
  ) => {
    const division = fetchApplicationType.positions.find(
      (div) => div.divisionName === divisionName
    );
    const result = division.subGroups.find(
      (pos) => pos.subGroupName === position
    );
    return result;
  };

  const getSubgroupsForDivision = (fetchApplicationType, divisionName) => {
    if (divisionName === 'All Positions') {
      return fetchApplicationType.positions
        .map((position) => position.subGroups)
        .flat()
        .filter((subGroup) => subGroup.hasOwnProperty('subGroupName'))
        .map((subGroup) => ({
          subGroupDivision: subGroup.subGroupDivision,
          subGroupName: subGroup.subGroupName,
          subGroupDescription: subGroup.subGroupDescription
        }));
    }
    const division = fetchApplicationType.positions.find(
      (position) => position.divisionName === divisionName
    );

    if (division) {
      return division.subGroups.map((subGroup) => ({
        subGroupDivision: subGroup.subGroupDivision,
        subGroupName: subGroup.subGroupName,
        subGroupDescription: subGroup.subGroupDescription
      }));
    } else {
      return null;
    }
  };

  const handlePopupOpen = (obj, division, position) => {
    setPopupContent(getInformationPosition(obj, division, position));
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  const handleButtonClick = () => {
    componentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Header />
      <Fade in>
        <Box>
          <Box
            mb={4}
            py={10}
            sx={{
              height: { xs: '80vh', md: '85vh' },
              width: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              background: theme.palette.background.paper,
              backgroundImage: `url(${background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <Typography
              mt={10}
              mb={4}
              px={{ xs: 2, sm: 3, md: 4 }}
              py={{ xs: 2, sm: 2, md: 2 }}
              variant="h1"
              color="primary.contrastText"
              fontSize={{ xs: '2.2rem', sm: '4rem', md: '6rem' }}
              fontWeight={700}
              textAlign="center"
              sx={{ bgcolor: 'primary.main', borderRadius: 6 }}
            >
              JOIN PROPULSE!
            </Typography>
            {process.env.REACT_APP_APPLY_FOR_POSITION === 'true' && (
              <>
                <Typography
                  variant="h4"
                  fontSize={{ xs: '1.4rem', sm: '1.9rem', md: '1.9rem' }}
                  fontWeight={600}
                  textAlign="center"
                  sx={{ textDecoration: 'underline' }}
                >
                  Application deadline: 23rd of March, 23:59
                </Typography>
                <Typography
                  mb={3}
                  variant="h4"
                  fontSize={{ xs: '1.4rem', sm: '1.9rem', md: '1.9rem' }}
                  fontWeight={600}
                  textAlign="center"
                >
                  {timeRemaining != -1
                    ? '(Time remaining: ' + timeRemaining + ')'
                    : '(Application period has ended)'}
                </Typography>
              </>
            )}
            <Typography
              variant="h2"
              fontSize={{ xs: '1.5rem', sm: '2rem', md: '2rem' }}
              fontWeight={700}
            >
              Our positions
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
                justifyContent: 'center',
                p: 4,
                width: { xs: '100%', sm: '80%', md: '60%' }
              }}
            >
              {currentDivisions.map((position, index) => (
                <Chip
                  key={position.divisionName}
                  label={position.divisionName}
                  color={
                    chosenDivision === position.divisionName
                      ? 'primary'
                      : 'default'
                  }
                  sx={{
                    backdropFilter: 'blur(4px)',
                    width: 'max-content',
                    px: { xs: 1, md: 2 },
                    height: { xs: 36, md: 46 },
                    borderRadius: 24,
                    fontSize: { xs: 14, md: 18 }
                  }}
                  onClick={() => {
                    setChosenDivision(position.divisionName);
                    handleButtonClick();
                  }}
                />
              ))}
            </Box>
          </Box>

          <Container maxWidth="lg">
            <PropulseCard
              sx={{
                my: 10,
                p: 4,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Container maxWidth="md">
                {process.env.REACT_APP_APPLY_FOR_POSITION === 'false' && (
                  <Typography
                    textAlign="center"
                    variant="h6"
                    fontSize="1rem"
                    fontWeight={400}
                    color="text.primary"
                  >
                    The application period is over, but you can always apply to
                    a position by sending in a general application to
                    contact@propulsentnu.no or the relevant group leader -
                    emails can be found by clicking on a position, or on the
                    team page!
                  </Typography>
                )}
                {process.env.REACT_APP_APPLY_FOR_POSITION === 'true' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 3
                      }}
                    >
                      <PropulseButton
                        href="https://docs.google.com/forms/d/e/1FAIpQLSeSSqHoc9x7NL5qarugvfvojXENkd6fuRjXqhbIdiGum97eow/viewform?usp=sharing"
                        target="_blank"
                        sx={{ px: { xs: 1.5, sm: 2 } }}
                      >
                        <Typography variant="h4" fontWeight={600}>
                          Apply For Board Positions
                        </Typography>
                      </PropulseButton>
                    </Box>
                    <Typography
                      textAlign="center"
                      variant="h6"
                      fontSize="1rem"
                      fontWeight={400}
                      color="text.primary"
                    >
                      {/* Click on a position to read more about it! */}
                      Click the button above to find the application form for
                      board positions.
                    </Typography>
                  </>
                )}
              </Container>
            </PropulseCard>
            <Box
              ref={componentRef}
              id="application-main-view"
              mb={4}
              sx={{
                width: '100%',
                minHeight: '600px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Grid container spacing={4}>
                {getSubgroupsForDivision(
                  fetchApplicationType,
                  chosenDivision
                ).map((position) => (
                  <Grid
                    key={position.subGroupName}
                    item
                    xs={12}
                    lg={6}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      zIndex: 10,
                      transition: 'ease-in .2s',
                      '&:hover': {
                        transform: 'scale(1.02)'
                      },
                      '&:active': {
                        transform: 'scale(1.015)'
                      }
                    }}
                  >
                    <PositionCard
                      title={position.subGroupName}
                      division={position.subGroupDivision}
                      desc={position.subGroupDescription}
                      setPosition={setChosenPosition}
                      handlePopupOpen={handlePopupOpen}
                      obj={fetchApplicationType}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
          {openPopup && (
            <PositionPopup
              openPopup={openPopup}
              close={handlePopupClose}
              positionInformationObject={popupContent}
            />
          )}

          <Footer />
        </Box>
      </Fade>
    </>
  );
}
