import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Box,
  Card,
  CardMedia,
  Fade,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';

import useSound from 'use-sound';
import funnySounds from '../../assets/sounds/funny_sounds.mp3';
import grinningEmoji from '../../assets/images/shitpost/grinning-emoji.png';
import haraldPlumber from '../../assets/images/shitpost/Harald-digital-plumber.jpg';
import rennyCart from '../../assets/images/shitpost/Renny_cart.webp';
import amogus from '../../assets/images/shitpost/among-us-twerk.gif';
import aintNoWay from '../../assets/images/shitpost/skeleton-spinning.gif';
import spinnyGhost from '../../assets/images/shitpost/ghost-spinning-ghost.gif';
import sadKermit from '../../assets/images/shitpost/sad-kermit.jpg';
import popcat from '../../assets/images/shitpost/popcat.gif';
import shreksophone from '../../assets/images/shitpost/shreksophone-saxophone.gif';
import thanosDancing from '../../assets/images/shitpost/thanos-dancing.gif';
import huhCat from '../../assets/images/shitpost/huh_cat.gif';
import holtan from '../../assets/images/teams/Team2024/avionics/AndersH.webp';

import React, { useContext } from 'react';
import { SecretContext } from '../../context/SecretContext';

import { ReactComponent as TeamLogo } from '../../assets/images/graphical/icons/teamlogo.svg';
import anonymAstronaut from '../../assets/images/misc/anoastro.png';

const MemberCard = ({ member, img, index }) => {
  const [imgState, setImgState] = React.useState(null);
  const [hover, setHover] = React.useState(false);
  const [imgError, setImgError] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const theme = useTheme();

  // Shitpost stuff (start)
  const { showSecret, setShowSecret } = useContext(SecretContext);
  const funnyPeople = [
    { name: 'Anders Hestad', soundID: 'nokiaArabic', funnyImg: aintNoWay },
    { name: 'Harald Bjerkeli', soundID: 'stinky', funnyImg: haraldPlumber },
    { name: 'Stian Alseth', soundID: 'villager', funnyImg: grinningEmoji },
    { name: 'Renny Pavlova Chausheva', soundID: 'none', funnyImg: rennyCart },
    {
      name: 'Ane Kirstine Havstad Morkemo',
      soundID: 'yahaha',
      funnyImg: popcat
    },
    {
      name: 'Tobias Ringdalen Thrane',
      soundID: 'spooky',
      funnyImg: spinnyGhost
    },
    { name: 'Maya Saint-Victor', soundID: 'sadViolin', funnyImg: sadKermit },
    {
      name: 'Ola Vanni Flaata',
      soundID: 'cantinaBand',
      funnyImg: shreksophone
    },
    {
      name: 'Even Mathisen Hugdal',
      soundID: 'mexicanAvengers',
      funnyImg: thanosDancing
    },
    { name: 'Sverre Ose Kristensen', soundID: 'THX' },
    { name: 'Thanathon Holm', soundID: 'huh', funnyImg: huhCat },
    { name: 'Anders Holtan', soundID: 'villager', funnyImg: holtan }
  ];
  // const funnyPerson = funnyPeople.find((person) => person.name === member.name);
  const funnyPerson = showSecret
    ? funnyPeople.find((person) => person.name === member.name)
    : undefined;

  const [play, { stop }] = useSound(funnySounds, {
    volume: 0.25,
    sprite: {
      stinky: [0, 2400],
      villager: [2470, 817],
      yahaha: [3553, 1427],
      nokiaArabic: [4989, 10491],
      sadViolin: [16035, 5036],
      spooky: [21081, 9348],
      cantinaBand: [30438, 7722],
      THX: [39470, 13570],
      huh: [54060, 6560],
      mexicanAvengers: [61630, 18690]
    }
  });
  const handleHover = (bool) => {
    setHover(bool);
    if (funnyPerson && bool) {
      play({ id: funnyPerson.soundID });
    } else {
      stop();
    }
  };
  // Shitpost stuff (end)

  const imageLoad = (imgSrc) => {
    import(`../../assets/images/teams/${imgSrc}`)
      .then((image) => {
        setImgState(image.default);
      })
      .catch((error) => {
        setImgState(anonymAstronaut);
      });
  };

  return (
    <Fade
      in
      sx={{ width: '100%', height: '100%' }}
      style={{ transitionDelay: index * 100 }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <Card
          elevation={8}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          onTouchStart={() => () => handleHover(true)}
          onTouchMove={() => () => handleHover(true)}
          onTouchCancel={() => handleHover(false)}
          // onMouseEnter={() => setHover(true)}
          // onMouseLeave={() => setHover(false)}
          // onTouchStart={() => setHover(true)}
          // onTouchMove={() => setHover(true)}
          // onTouchCancel={() => setHover(false)}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '20px',
            position: 'relative',
            overflow: 'hidden',
            zIndex: 10,
            '&:hover': {
              '& .MainBox.MuiBox-root': {
                padding: 1
              },
              '& .LogoBox.MuiGrid-item': {
                display: 'flex',
                opacity: 1,
                borderRight: `${theme.palette.divider} solid 1px`
              },
              '& .LinkBox.MuiBox-root': {
                opacity: 1
              },
              '& .MuiGrid-container': {
                background: `${theme.palette.background.paper}80`,
                backdropFilter: 'blur(12px)',
                padding: 0
              },
              '& .MuiTypography-h6': {
                padding: 1,
                borderBottom: `${theme.palette.divider} solid 1px`
              },
              '& .MuiTypography-body2': {
                padding: 1
              },
              '& .MuiCardMedia-root': { WebkitTransform: 'scale(1.1)' },

              // Shitpost stuff
              backgroundImage: funnyPerson
                ? funnyPerson.funnyImg
                  ? `url(${funnyPerson.funnyImg})`
                  : 'none'
                : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }
          }}
        >
          <CardMedia
            component="img"
            src={
              imgError ? anonymAstronaut : imgState ? imgState : imageLoad(img)
            }
            alt={member.name}
            onError={() => setImgError(true)}
            onLoad={() => setLoaded(true)}
            sx={{
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center top',
              WebkitTransition: '3s',
              WebkitTransitionTimingFunction: 'cubic-bezier(.08,.92,0,01)',
              transition: 'opacity 0.5s ease-in-out',
              opacity: loaded ? 1 : 0,

              // Shitpost stuff
              '&:hover': {
                opacity: funnyPerson ? (funnyPerson.funnyImg ? 0 : 1) : 1
              }
            }}
          />
          {(member.linkedIn || member.email) && (
            <Box
              className="LinkBox"
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display: 'flex',
                padding: 0.5,
                borderRadius: '20px',
                background: `${theme.palette.background.paper}80`,
                opacity: 0,
                backdropFilter: 'blur(12px)',
                transition: '3s',
                transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)'
              }}
            >
              {member.linkedIn && (
                <IconButton
                  color="inherit"
                  onClick={() => window.open(member.linkedIn, '_blank')}
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {member.email && (
                <IconButton
                  color="inherit"
                  onClick={() =>
                    (window.location.href = 'mailto:' + member.email)
                  }
                >
                  <EmailRoundedIcon />
                </IconButton>
              )}
            </Box>
          )}
          {/* <Box
            className="LinkBox"
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              display: 'flex',
              padding: 0.5,
              borderRadius: '20px',
              background: `${theme.palette.background.paper}80`,
              opacity: 0,
              backdropFilter: 'blur(12px)',
              transition: '3s',
              transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)'
            }}>
            <IconButton color="inherit">
              <LinkedInIcon />
            </IconButton>
            <IconButton color="inherit">
              <EmailRoundedIcon />
            </IconButton>
          </Box> */}
          <Box
            className="MainBox"
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              transition: '3s',
              transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)'
            }}
          >
            <Grid
              container
              sx={{
                width: '100%',
                p: 3,
                borderRadius: '20px',
                background: `linear-gradient(0deg, ${
                  theme.palette.mode === 'dark'
                    ? theme.palette.grey[800]
                    : theme.palette.background.paper
                } 0%, #00000000 100%)`,
                display: 'flex'
              }}
            >
              <Grid
                item
                xs={3}
                className="LogoBox"
                sx={{
                  display: 'none',
                  minHeight: 60,
                  opacity: 0,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <TeamLogo width={50} fill={theme.palette.secondary.main} />
              </Grid>
              <Grid
                item
                xs={hover ? 9 : 12}
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <Typography
                  variant="h6"
                  color="text.primary"
                  fontWeight={700}
                  sx={{
                    transition: '3s',
                    transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)'
                  }}
                >
                  {member.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{
                    transition: '3s',
                    transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)'
                  }}
                >
                  {funnyPerson
                    ? funnyPerson.name == 'Maya Saint-Victor'
                      ? "Ane's Assistant"
                      : member.role
                    : member.role}
                  {/* {member.role} */}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Fade>
  );
};

export default MemberCard;
