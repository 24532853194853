import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Typography,
  useTheme,
  Grid,
  Link,
  Fade,
  Slide,
  CardActionArea,
  useMediaQuery
} from '@mui/material';
import {
  motion,
  useScroll,
  useTransform,
  useVisualElementContext
} from 'framer-motion';

import React, { useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import BirkelandLaunchNight from '../../assets/images/backgrounds/birkeland-launch-night.webp';
import BirkelandLaunch from '../../assets/images/backgrounds/birkeland-launch.webp';
import valemonBackdrop from '../../assets/images/backgrounds/valemon_backdrop.jpg';
import bifrostBackdrop from '../../assets/images/backgrounds/bifrost_backdrop.webp';
import ValemonStaticfire from '../../assets/videos/Valemon_staticfire_2.mp4';
import birkelandBackdrop from '../../assets/images/backgrounds/birkeland_backdrop.png';
import valemonPatch from '../../assets/images/rocket-projects/mission-patches/valemon_patch.png';
import BirkelandPatch from '../../assets/images/rocket-projects/mission-patches/Birkeland_missionPatch.png';
import BifrostPatch from '../../assets/images/rocket-projects/mission-patches/bifrost_patch.png';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import InstagramFeed from '../../components/core/Home/InstagramFeed';
import YoutubeVideo from '../../components/core/Home/YoutubeVideo';
import PropulseButton from '../../components/core/Universal/PropulseButton';
import PropulseCard from '../../components/core/Universal/PropulseCard';
import getTimeRemaining from '../../components/misc/Countdown/getTimeRemaining';
import UpcomingEvents from './UpcomingEvents';
import './Home.css';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const projectReveal = true;
  const timeRemaining = getTimeRemaining('2024-08-26T23:59:59');
  const videoRef = useRef(null);
  const disableFrontpageJoinbtn = true;

  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    container: containerRef
  });

  const opacityAnim1 = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const opacityAnim2 = useTransform(
    scrollYProgress,
    // [0.25, isMobile ? 0.3 : 0.5, isMobile ? 0.45 : 0.6, isMobile ? 0.5 : 0.75],
    // [0, 1, 1, 0]
    // [0, isMobile ? 0.19 : 0.26, isMobile ? 0.36 : 0.61],
    // [0, 1, 0]
    [0, isMobile ? 0.19 : 0.2, isMobile ? 0.19 : 0.35, isMobile ? 0.36 : 0.5],
    [0, 1, 1, 0]
  );

  const handleClick = (link) => {
    const timeout = setTimeout(() => {
      navigate(link, { replace: false });
    }, 200);
    return () => clearTimeout(timeout);
  };

  // React.useEffect(() => console.log(box3IsVisible), [box3IsVisible]);

  //This is to ensure the muted attribute is set on the video element, as the normal way to do it in React doesn't work.
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute('muted', '');
      videoRef.current.muted = true;
    }
  }, []);

  return (
    <Fade in>
      <Box
        ref={containerRef}
        sx={{
          scrollSnapType: 'y mandatory',
          scrollSnapStop: 'always',
          height: '100dvh',
          width: '100vw',
          overflowY: 'scroll',
          position: 'relative'
        }}
      >
        <motion.video
          /* src={
            theme.palette.mode === 'dark'
              ? BirkelandLaunchNight
              : BirkelandLaunch
          } */
          ref={videoRef}
          src={ValemonStaticfire}
          autoPlay
          muted
          loop
          playsInline
          style={{
            position: 'fixed',
            zIndex: -1,
            inset: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: isMobile ? '60% 50%' : '50% 90%',
            opacity: opacityAnim1
          }}
        />

        <motion.img
          src={valemonBackdrop}
          style={{
            position: 'fixed',
            zIndex: -1,
            inset: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: opacityAnim2
          }}
        />

        <Box
          sx={{
            width: '100%',
            height: '100vh',
            scrollSnapAlign: { xs: 'none', md: 'center' },
            position: 'relative',
            overflow: 'hidden',
            backgroundSize: 'cover'
          }}
        >
          <Header
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: 1000
            }}
          />

          <Slide direction="up" in timeout={1500}>
            <Box position="absolute" left="12%" top="30%" maxWidth={'70vw'}>
              <Fade in timeout={7000}>
                <Box>
                  <Typography
                    variant="h1"
                    fontWeight={800}
                    color="transparent"
                    // textShadow="rgb(0 0 0 / 40%) 0px 4px 5px"
                    sx={{
                      textShadow: 'rgb(0 0 0 / 30%) 0px 4px 5px',
                      background:
                        'linear-gradient(to right, #fcad03, #FF7A3C, #602B8D)',
                      WebkitBackgroundClip: 'text',
                      backgroundClip: 'text'
                    }}
                    fontSize={{ xs: 30, sm: 40, md: 65 }}
                  >
                    PROJECT HEIMDALL
                    {/* YOUR STEPPING <br />
                   STONE TO SPACE */}
                  </Typography>
                  {process.env.REACT_APP_APPLY_FOR_POSITION === 'true' &&
                    !disableFrontpageJoinbtn && (
                      <>
                        <NavLink to="/Join">
                          <PropulseButton
                            // href="https://docs.google.com/forms/d/e/1FAIpQLScDcQXZWqp6F2F44dmckoec-EUiDmkAenlvy3U_0OecKkc1vg/viewform"
                            sx={{ px: { xs: 1.5, sm: 2 }, mt: 3 }}
                          >
                            <Typography
                              variant="h4"
                              fontWeight={600}
                              fontSize={{ xs: 20, sm: 25, md: 35 }}
                            >
                              Join propulse!
                              {/* Apply to board positions! */}
                            </Typography>
                          </PropulseButton>
                        </NavLink>
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color="white"
                          mt={1}
                          fontSize={{ xs: 16, sm: 20, md: 25 }}
                          sx={{ textShadow: 'rgb(0 0 0 / 40%) 0px 4px 5px' }}
                        >
                          {timeRemaining != -1
                            ? 'Application period ends in ' + timeRemaining + ''
                            : 'Application period has ended'}
                        </Typography>
                      </>
                    )}
                  {projectReveal && (
                    <>
                      <Typography
                        variant="h4"
                        fontWeight={400}
                        color="white"
                        mt={2}
                        fontSize={{ xs: 16, sm: 20, md: 25 }}
                        sx={{ textShadow: 'rgb(0 0 0 / 40%) 0px 4px 5px' }}
                      >
                        LAUNCHING FROM{' '}
                        <Typography
                          component="span"
                          variant="h4"
                          fontWeight={700}
                          color="yellow"
                          fontSize={{ xs: 16, sm: 20, md: 25 }}
                          sx={{ textShadow: 'rgb(0 0 0 / 60%) 0px 4px 5px' }}
                        >
                          NORWEGIAN SOIL
                        </Typography>{' '}
                        IN 2025
                      </Typography>

                      <PropulseButton
                        sx={{ mt: 4 }}
                        onClick={() => handleClick('/Projects/Project2025')}
                      >
                        Learn more
                      </PropulseButton>
                    </>
                  )}
                </Box>
              </Fade>
            </Box>
          </Slide>
        </Box>

        {/* <UpcomingEvents /> */}

        <Box
          sx={{
            scrollSnapAlign: { xs: 'none', md: 'center' },
            height: '100dvh',
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            py: 4
          }}
        >
          <div style={{ width: '100%' }}>
            <Container maxWidth="lg">
              <Grid container spacing={4} p={6}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    component="img"
                    src={valemonPatch}
                    sx={{
                      width: '60%',
                      display: { xs: 'none', md: 'initial' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    fontSize={{ xs: 16, md: 20 }}
                    color="white"
                    pl={1}
                    pt={4}
                    letterSpacing={1}
                  >
                    OUR LATEST ROCKET
                  </Typography>
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    color="white"
                    // pl={1}
                    pb={2}
                    letterSpacing={1}
                    fontSize={{ xs: 50, sm: 58, md: 80 }}
                  >
                    Project VALEMON
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    color="white"
                    pl={1}
                  >
                    Building on Bifrost&apos;s bi-propellant engine, Valemon was
                    built to reach even higher, and this time from norwegian
                    soil.
                    <br />
                    Check out the project page to learn more about this project.
                  </Typography>
                  <PropulseButton
                    sx={{ mt: 4 }}
                    onClick={() => handleClick('/Projects/Valemon')}
                  >
                    Learn more
                  </PropulseButton>
                </Grid>
                <Grid item xs={0} md={1} />
              </Grid>
            </Container>
          </div>
        </Box>

        <Box
          sx={{
            width: '100%',
            minHeight: '100vh',
            scrollSnapAlign: { xs: 'none', md: 'start' },
            pb: '3vh'
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              pt: { xs: 4, md: 12 }
            }}
          >
            {/* <Card
              component={CardActionArea}
              onClick={() => handleClick('/Projects/Penumbra')}
              disableRipple
              sx={{
                boxShadow: 4,
                width: '100%',
                // background: `linear-gradient(0deg, ${theme.palette.divider} 0%, ${theme.palette.background.paper} 100%)`,
                borderRadius: '20px',
                position: 'relative',
                transition: 'ease-in 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)'
                },
                '&:active': {
                  transform: 'scale(1)'
                }
              }}>
              <CardMedia
                component="img"
                image={penumbraBackdrop}
                sx={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}
              />
              <Grid
                container
                sx={{
                  p: 4,
                  width: '100%',
                  height: '100%'
                }}>
                <Grid
                  item
                  xs={12}
                  md={5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ zIndex: 10 }}>
                  <img src={PenumbraLogo} alt="Penumbra logo" width="300px" />
                </Grid>
                <Grid item xs={12} md={7} sx={{ zIndex: 10 }}>
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    fontSize={{ xs: 16, md: 20 }}
                    color="text.primary"
                    pl={1}
                    pt={2}>
                    SOFTWARE R&D
                  </Typography>
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    color="primary.main"
                    // pl={1}
                    pb={1}
                    letterSpacing="-0.03em"
                    fontSize={{ xs: 60, md: 80 }}>
                    Penumbra
                  </Typography>
                  <Typography variant="body1" fontWeight={400} color="primary.white" pl={1}>
                    Our multifunctional cutting-edge rocket simulator.
                  </Typography>
                  <Typography variant="body1" color="primary.light" sx={{ p: 1, mt: 2 }}>
                    Click to Learn More
                  </Typography>
                </Grid>
              </Grid>
            </Card> */}
            <Card
              component={CardActionArea}
              onClick={() => handleClick('/Projects/Bifrost')}
              disableRipple
              sx={{
                boxShadow: 4,
                width: '100%',
                background:
                  'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)',
                borderRadius: '20px',
                position: 'relative',
                transition: 'ease-in 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)'
                },
                '&:active': {
                  transform: 'scale(1)'
                }
              }}
            >
              <CardMedia
                component="img"
                image={bifrostBackdrop}
                sx={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
              <Grid
                container
                sx={{
                  p: 4,
                  width: '100%',
                  height: '100%'
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ zIndex: 10 }}
                >
                  <img
                    src={BifrostPatch}
                    alt="Birkeland mission patch"
                    width="300px"
                  />
                </Grid>
                <Grid item xs={12} md={7} sx={{ zIndex: 10 }}>
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    fontSize={{ xs: 16, md: 20 }}
                    color="white"
                    pl={1}
                    pt={2}
                  >
                    PROJECT 2023
                  </Typography>
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    color="white"
                    // pl={1}
                    pb={1}
                    letterSpacing="-0.03em"
                    fontSize={{ xs: 52, sm: 60, md: 80 }}
                  >
                    Bifrost
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    color="white"
                    pl={1}
                  >
                    Our first time using a self designed & developed engine, as
                    well as the first norwegian rocket to use a bipropellant
                    engine. Bifrost competed in the European Rocketry Challenge
                    2023, and placed 1st in the Liquid 3 km category, and 3rd in
                    vehicle design overall.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="white"
                    sx={{ p: 1, mt: 2 }}
                  >
                    Click to Learn More
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Card
              component={CardActionArea}
              onClick={() => handleClick('/Projects/Birkeland')}
              disableRipple
              sx={{
                boxShadow: 4,
                width: '100%',
                background:
                  'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)',
                borderRadius: '20px',
                position: 'relative',
                transition: 'ease-in 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)'
                },
                '&:active': {
                  transform: 'scale(1)'
                }
              }}
            >
              <CardMedia
                component="img"
                image={birkelandBackdrop}
                sx={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
              <Grid
                container
                sx={{
                  p: 4,
                  width: '100%',
                  height: '100%'
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ zIndex: 10 }}
                >
                  <img
                    src={BirkelandPatch}
                    alt="Birkeland mission patch"
                    width="300px"
                  />
                </Grid>
                <Grid item xs={12} md={7} sx={{ zIndex: 10 }}>
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    fontSize={{ xs: 16, md: 20 }}
                    color="black"
                    pl={1}
                    pt={2}
                  >
                    PROJECT 2022
                  </Typography>
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    color="primary.main"
                    // pl={1}
                    pb={1}
                    letterSpacing="-0.03em"
                    fontSize={{ xs: 52, sm: 60, md: 80 }}
                  >
                    Birkeland
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    color="black"
                    pl={1}
                  >
                    With an apogee of 9014m and a successful recovery, it placed
                    3rd overall and 2nd in its class at Spaceport America Cup
                    2022.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary.light"
                    sx={{ p: 1, mt: 2 }}
                  >
                    Click to Learn More
                  </Typography>
                </Grid>
              </Grid>
            </Card>

            {/* INSTAGRAM API IS NO LONGER SUPPORTED, but keeping the code in case of future use
            <PropulseCard sx={{ overflow: 'hidden' }}>
              <CardContent>
                <Typography
                  variant="h4"
                  fontWeight={700}
                  color="primary.main"
                  pl={1}
                >
                  Recent Instagram Posts
                </Typography>
              </CardContent>
              <Divider />
              <InstagramFeed />
            </PropulseCard> */}

            <PropulseCard sx={{ overflow: 'hidden' }}>
              <CardContent>
                <Typography
                  variant="h4"
                  fontWeight={700}
                  color="primary.main"
                  pl={1}
                >
                  Videos
                </Typography>
              </CardContent>
              <Divider />
              <YoutubeVideo />
            </PropulseCard>

            <PropulseCard>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <CardContent>
                    <Typography
                      variant="h4"
                      fontWeight={700}
                      color="primary.main"
                      pl={1}
                    >
                      Contact Us
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardContent sx={{ p: 4 }}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={12}>
                        <Typography variant="h6">Email</Typography>
                        <Typography variant="body1">
                          contact@propulsentnu.no
                        </Typography>
                        <Typography variant="h6" mt={2}>
                          Address
                        </Typography>
                        <Typography variant="body1">
                          Klæbuveien 153,
                          <br />
                          7031 Trondheim,
                          <br />
                          Norway
                        </Typography>
                        {/* <Link href="/Media" display={{ xs: 'none', sm: 'initial', md: 'none' }}>
                          <PropulseButton sx={{ mt: 2, ml: 2 }}>Media Overview</PropulseButton>
                        </Link> */}
                      </Grid>
                      <Grid item xs={12} sm={6} md={12}>
                        <Typography variant="h6" mt={{ xs: 2, sm: 0, md: 2 }}>
                          Bachelor / Master Thesis
                        </Typography>
                        <Typography variant="body2">
                          Would you like to write your thesis at Propulse NTNU?
                          Send us an e-mail! We have already made a list of
                          suggested topics, but you are free to make your own
                          suggestions as well.
                        </Typography>
                        <Link
                          target="_blank"
                          href="https://docs.google.com/document/d/1YvPVY513u6aYWWeImhtVENvYgfwqxD46hwkcvfuioVg/edit?usp=sharing"
                        >
                          <PropulseButton
                            sx={{ mt: 2, width: { xs: '100%', md: 'initial' } }}
                          >
                            Thesis suggestions
                          </PropulseButton>
                        </Link>
                        <Link href="/Media">
                          <PropulseButton
                            sx={{
                              mt: 2,
                              ml: { xs: 0, md: 2 },
                              width: { xs: '100%', md: 'initial' }
                            }}
                          >
                            Media Overview
                          </PropulseButton>
                        </Link>
                        <br />
                        <Link href="/Donate">
                          <PropulseButton
                            sx={{
                              mt: 2,
                              // ml: { xs: 0, md: 2 },
                              width: { xs: '100%', md: 'initial' }
                            }}
                          >
                            Donate
                          </PropulseButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item xs={12} md={6} height="520px">
                  <CardMedia
                    component="iframe"
                    width="100%"
                    height={isMobile ? 520 : 530}
                    src="https://maps.google.com/maps?q=Kl%C3%A6buveien%20153,&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  />
                </Grid>
              </Grid>
            </PropulseCard>
            <Footer />
          </Container>
        </Box>

        <Box sx={{ scrollSnapAlign: { xs: 'none', md: 'start' } }} />
      </Box>
    </Fade>
  );
};

export default Home;
