import './stylesheets/stylesheet.css';
import './App.css';
import React from 'react';
import {
  ColorModeContext,
  RoutesCollection
} from './components/Header/Navbar.jsx';
import ScrollToTop from './scrollToTop.jsx';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from './themes.js';
import { useState } from 'react';
import { useMemo } from 'react';
import { SecretContext } from './context/SecretContext.js';

function App() {
  const [mode, setMode] = useState(() => {
    const localmode = JSON.parse(localStorage.getItem('theme'));
    if (!localmode) return 'dark';
    return localmode;
  });

  const [showSecret, setShowSecret] = useState(false);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );

  React.useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(mode));
  }, [mode]);

  return (
    <div className="App">
      <SecretContext.Provider value={{ showSecret, setShowSecret }}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={mode === 'dark' ? darkTheme : lightTheme}>
            <CssBaseline />
            <ScrollToTop>
              <RoutesCollection />
            </ScrollToTop>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </SecretContext.Provider>
    </div>
  );
}

export default App;
